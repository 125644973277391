<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="kaHao" label="卡号" />
            <x-detail-item prop="shouJiHao" label="手机号" />
            <x-detail-item prop="xingMing" label="姓名" />
            <x-detail-item prop="bianDongJinE" label="变动金额" />
            <x-detail-item prop="bianDongLX" label="变动类型" >
                <x-dict-show :code="entity.bianDongLX" dictType="T_BianDong_LX"/>
            </x-detail-item>
            <x-detail-item prop="bianDongChangJing" label="变动场景" >
                <x-dict-show :code="entity.bianDongChangJing" dictType="T_BianDong_CJ"/>
            </x-detail-item>
            <x-detail-item prop="bianDongSJ" label="变动时间" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
            <x-detail-item prop="bianDongMenDian" label="变动门店" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/YuEJiLu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                kaHao: "", // 卡号
                shouJiHao: "", // 手机号
                xingMing: "", // 姓名
                bianDongJinE: "", // 变动金额
                bianDongLX: "", // 变动类型
                bianDongChangJing: "", // 变动场景
                bianDongSJ: "", // 变动时间
                caoZuoRen: "", // 操作人
                bianDongMenDian: "", // 变动门店
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>